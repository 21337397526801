import React, { lazy, Suspense } from 'react'
import { Usabilla, useUsabilla } from '@fs/zion-usabilla'
import { useOverlay } from '@fs/zion-ui'
import { usabillaConfig } from '../../../config'
import { rootsTechLogoWhitePng } from '../../../images'
import { useFeedback } from './helpers'

const FallbackOverlay = lazy(() => import('./FallbackOverlay'))

export const FeedbackButton = ({ as: Tag, onClick, ...props }) => {
  const overlay = useOverlay()
  const { feedbackUrl: url } = useFeedback()
  const { open } = useUsabilla({ url })

  const onOpen = async (e) => {
    onClick?.(e)
    const wasAbleToOpen = await open()
    if (!wasAbleToOpen) {
      // We need to tell the overlay what button to focus on when closing. currentTarget is undefined (I'm not sure why), but we can just use target and it'll _probably_ be ok
      e.currentTarget = e.target
      overlay.handleClick(e)
    }
  }

  if (Tag) {
    return (
      <Suspense fallback={null}>
        <Tag {...props} onClick={onOpen} />
        {(overlay.isOpen || overlay.transitioning) && (
          <FallbackOverlay communityLink="https://community.familysearch.org/" {...overlay} />
        )}
      </Suspense>
    )
  }

  return <Usabilla url={url} hideOnMobile logo={rootsTechLogoWhitePng} localeMap={usabillaConfig.localeMap} />
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import { FeedbackButton, PillButton } from './buttons'

export default function SessionFeedbackButton({ fullWidth = false, disabled = false }) {
  const [t] = useTranslation()
  return (
    <FeedbackButton
      as={PillButton}
      onClick={() => {
        trackLink({ name: 'Session Feedback: Button' })
      }}
      data-testid="session-feedback-button"
      fullWidth={fullWidth}
      disabled={disabled}
      emphasis="medium"
    >
      {t('session-feedback.action', 'Session Feedback')}
    </FeedbackButton>
  )
}

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { brightspotConfig } from '../../config'

const apiPaths = brightspotConfig.apiPaths

const getAuthHeaderLink = (apiKey) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-api-key': apiKey,
      },
    }
  })
}

const getAcceptLanguageLink = (userLocale, useBrowserLanguage) => {
  const acceptLanguage = userLocale === 'zh-hans' ? 'zh-CN' : userLocale
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Accept-Language': useBrowserLanguage ? acceptLanguage : '*',
      },
    }
  })
}

const getLink = ({ persistQueries, envConfig, useBrowserLanguage, userLocale }) => {
  const link = createHttpLink({ uri: `${envConfig.uri}${apiPaths.cae}` })
  const authLink = getAuthHeaderLink(envConfig.apiKey)

  let persistedQueryLink = null
  if (persistQueries) {
    persistedQueryLink = createPersistedQueryLink({
      useGETForHashedQueries: true,
    })
  }

  const tailLinks = getAcceptLanguageLink(userLocale, useBrowserLanguage ?? true)
    .concat(authLink)
    .concat(link)

  if (persistedQueryLink) {
    return persistedQueryLink.concat(tailLinks)
  }

  return tailLinks
}

const getClient = ({ persistQueries, envConfig, useBrowserLanguage, userLocale }) => {
  return new ApolloClient({
    link: getLink({ persistQueries, envConfig, useBrowserLanguage, userLocale }),
    cache: new InMemoryCache(),
  })
}

export default function getBrightspotClient({ persistQueries = false, envConfig, userLocale }) {
  return {
    caeClient: getClient({ persistQueries, envConfig, userLocale }),
    npcClient: getClient({ persistQueries: false, envConfig, userLocale }),
    allLanguagesClient: getClient({ persistQueries, envConfig, useBrowserLanguage: false, userLocale }),
  }
}
